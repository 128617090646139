import React from 'react'
import { GatsbyImage as Img } from 'gatsby-plugin-image'
import { Col, Container, Row } from 'react-bootstrap'
import SectionTitle from './components/SectionTitle'
import Widget from './components/widget'
import About from './style'
import { useStaticQuery, graphql } from "gatsby"
import Data from "~data-yaml/industries.yml"

export default function AboutSection() {
  const iName = Data.about_section.image
  const iShape = Data.about_section.shape

  const data = useStaticQuery(graphql`
  query industryAboutImage($image: String ! = "image/industries/") {
    allFile(filter: {relativePath: {regex: $image}}) {
      edges {
        node {
          base
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
   }
`)
const iData = (name) => {
  let iDat = undefined
  for (let i = 0; i<data.allFile.edges.length; i++) {
    if (data.allFile.edges[i].node.base == name) {
      iDat = data.allFile.edges[i].node.childImageSharp.gatsbyImageData
    }
  }
  return iDat
}

return (
    <About backgroundColor="#f2f5fb">
      <Container>
        <Row className="justify-content-center">
          <Col className="col-xl-6 col-lg-8 col-md-9 col-xs-10 text-center">
            <SectionTitle
              subTitle={Data.about_section.subtitle}
              title={Data.about_section.title}
              titleProps={{ mb: "50px" }}
              subTitleProps={{ mb: "15px" }}
            />
          </Col>
        </Row>
        <Row className="align-items-center justify-content-center">
          <Col className="col-xxl-3 col-lg-4 col-md-6 col-sm-8 col-xs-9 order-2 order-lg-1">
            {Data.about_section.col_1.map(({ title, text, icon }, i) => {
              return (
                <Widget 
                directionXS="row-reverse" 
                title={title} 
                icon={icon} 
                text={text} 
                key={"w-"+i} />
              )
            }
            )}
          </Col>
          <Col xs="12" className="col-xxl-6 col-lg-4 col-md-8 col-xs-9 order-1 order-lg-2 text-center">
            <About.ImageContent>
              <Img image={iData(iName)} alt="content" layout="fullWidth" placeholder="blurred" />
              <About.Shape>
                <Img image={iData(iShape)} alt="content" layout="fullWidth" placeholder="blurred" />
              </About.Shape>
            </About.ImageContent>
          </Col>
          <Col className="col-xxl-3 col-lg-4 col-md-6 col-sm-8 col-xs-9 order-3">
            {Data.about_section.col_2.map(({ title, text, icon }, i) => {
              return (
                <Widget directionXS="row-reverse" title={title} icon={icon} text={text} key={i}/>
              )
            }
            )}
          </Col>
        </Row>
      </Container>
    </About>
  )
}