import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { Link } from '~components'
import ServicesCard from './Components/Card'
import Service from './style'
import SectionTitle from '~sections/Common/SectionTitle'
import { Col, Container, Row } from 'react-bootstrap'
import Data from "~data-yaml/industries.yml"

const ServicesSection = () => {
  const data = useStaticQuery(graphql`
  query {
    allMdx(filter: {frontmatter: {type: {eq: "industryDetails"}}}) {
      nodes {
        id
        excerpt
        slug
        frontmatter {
          icon
          title
          iconColor
        }
      }
    }
  }
`)
  return (
    <Service>
      <Container className="container">
        <Row className="row justify-content-center">
          <Col className="col-xl-8 text-center">
            <SectionTitle
              subTitle={Data.p_section.subtitle}
              title={Data.p_section.title}
              titleProps={{ mb: "40px", mbLG: "75px" }}
              subTitleProps={{ mb: "20px" }}
            />
          </Col>
        </Row>
        <Row className="row justify-content-center">
          {data.allMdx.nodes.map((p, i) => {
            return (
              <Col xs="12" className="col-xl-3 col-lg-4 col-md-6 col-xs-8">
              <Link to={"/"+p.slug}>
                <ServicesCard 
                  key={p.id}
                  title={p.frontmatter.title}
                  icon={p.frontmatter.icon} 
                  iconColor={p.frontmatter.iconColor} 
                  text={p.excerpt} />
                </Link>
              </Col>
              )
          })}
        </Row>
      </Container>
    </Service>
  )
}

export default ServicesSection

