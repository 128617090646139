import React from "react";
import { PageWrapper } from "~components/Core";
import HeroSection from '~sections/Common/Hero'
import IndustrySection from '~sections/Industries/Industry'
import AboutSection from '~sections/Industries/About'
import ContentSectionOne from '~sections/Industries/ContentOne'
import TestimonialSection from '~sections/Industries/Testimonial'
import PromoSection from '~sections/Industries/Promo'
import CtaSection from '~sections/Industries/Cta'
import FooterFour from '~sections/Common/Footer'
import industries from "~data-yaml/industries.yml"
import HeroBg from "~image/heroimages/industries/hero_bg.jpg"
import heroOverlay from "~image/heroimages/industries/overlay.png"

const header = {
  headerClasses: "site-header site-header--menu-end dark-header site-header--sticky",
  containerFluid:false,
  darkLogo:false,
}

export default function HomeApp() {
  return (
    <PageWrapper headerConfig={header}>
        <HeroSection 
          title={industries.hero.title}
          subtitle={industries.hero.subtitle}
          text={industries.hero.text}
          HeroBg={HeroBg}
          Overlay={heroOverlay}
        />
        <IndustrySection/>
        <AboutSection/>
      {/* <ContentSectionOne/> */}
        <TestimonialSection/>
      {/* <PromoSection/>
        <CtaSection/> */}
        <FooterFour/>
    </PageWrapper>
  )
}
